body > footer {
  background-color: #99a4ae;
  padding: 2rem;
  min-height: 20rem; }
  body > footer address {
    font-size: 75%;
    font-style: normal; }
  body > footer h6 {
    text-transform: uppercase;
    font-weight: 500; }
  body > footer .newsletter input,
  body > footer .newsletter button {
    border-radius: 0;
    display: inline-block; }
  body > footer .newsletter input {
    font-size: 80%; }
  body > footer .newsletter button[type=submit] {
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    background-color: #364b5f;
    border: 1px solid #364b5f;
    height: initial; }
    body > footer .newsletter button[type=submit]:hover {
      background-color: #99a4ae; }
  body > footer .newsletter .description {
    color: white;
    font-size: 90%;
    font-weight: 200; }
